import * as React from 'react'
import Container from '../components/Container/Container'
import {graphql, Link, useStaticQuery} from "gatsby";
import styles from "../styles/pages/index.module.scss";

export interface Query {
  allContentfulBlogPost: AllContentfulBlogPost
}

export interface AllContentfulBlogPost {
  edges: Edge[]
}

export interface Edge {
  node: Post
}

export interface Post {
  title: string,
  slug: string,
  skipOnHome: boolean,
  createdAt: string,
  publicationDate: string,
  thumbnail: {
    file: {
      url?: string
    }
  }
}

const IndexPage = () => {
  const posts: Query = useStaticQuery(graphql`
  query {
   allContentfulBlogPost (sort: { fields: createdAt, order: DESC } ) {
    edges {
      node {
        title,
        skipOnHome,
        slug,
        createdAt(formatString:"DD/MM/YYYY"),
        thumbnail {
          file {
            url
          }
        }
      }
    }
   }
  }`)
  return <Container>
    <ul className={styles.list}>
      {posts.allContentfulBlogPost.edges.filter((edge: Edge) => !edge.node.skipOnHome).map((edge: Edge) => (
        <li>
          <Link to={`/blog/${edge.node.slug}`}>
            <img src={edge.node.thumbnail.file.url} alt={edge.node.title}/>
            <div className={styles.titleWrapper}>
              <h5>{edge.node.publicationDate}</h5>
              <h4>{edge.node.title}</h4>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  </Container>;
}

export default IndexPage;

